import { runPortal } from '@piral/portal-core';
const authProviders = {
    local: () => import('./auth/local'),
    microsoft: () => import('./auth/microsoft'),
    oauth2: () => import('./auth/oauth2'),
    oidc: () => import('./auth/oidc'),
    ldap: () => import('./auth/ldap'),
    saml: () => import('./auth/saml'),
};
function setConfig(cfg) {
    for (const key of Object.keys(cfg)) {
        window[key] = cfg[key];
    }
}
function start() {
    const portalUrl = window.CLOUD_WWW_ADDRESS || document.location.origin;
    const apiUrl = window.CLOUD_API_ADDRESS || portalUrl;
    const auth = (window.CLOUD_AUTH_PROVIDER || 'local');
    const consent = window.CLOUD_SHOW_CONSENT || false;
    const promise = authProviders[auth]();
    promise.then(({ auth, Login, Logout }) => {
        runPortal({
            apiUrl,
            portalUrl,
            auth,
            consent,
            landingPages: {
                '/': Login,
            },
            plans: ['premium'],
            portalPages: {
                '/logout': Logout,
            },
        });
    });
}
if (process.env.NODE_ENV === 'development') {
    const baseElement = document.head.appendChild(document.createElement('base'));
    baseElement.href = location.origin;
}
__webpack_public_path__ = document.querySelector('base').href;
fetch(`${__webpack_public_path__}api/v1/ui-config`)
    .then(res => res.json())
    .then(cfg => setConfig(cfg))
    .then(() => {
    if (window.CLOUD_HAS_ERROR) {
        location.href = '/error?code=500';
    }
    else {
        start();
    }
});
